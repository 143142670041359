import { Storage } from '@ionic/storage-angular';

export class ValidarToken{
    public token: string;
    public rol: string;
    public id: any;
    public idMongo: string;
    public idAdmin: any;
    public url: any;
    public urlQr: any;
    constructor(
        private storage: Storage,
    ) {
        this.getStorage();
    }

      getStorage = () => {
        this.storage.get('token')
        .then((token) => {
          this.token = token;
          // console.log(token);
        });

        this.storage.get('usuario')
        .then((usuario) => {
          // console.log(usuario, 'desde storage');
          if(usuario.url) {
            this.url = usuario.url;
            // console.log(this.url);
          }

          if(usuario.urlQr) {
            this.urlQr = usuario.urlQr;
            // console.log(this.urlQr);
          }
          this.rol = usuario.rol;
          this.idMongo = usuario.idMongo;
          this.idAdmin = usuario.idAdmin;
          if(this.rol === 'admin') {
            this.id = usuario.idAdmin;
          } else {
            this.id = usuario.idAgente;
          }
        });
      };

// eslint-disable-next-line eol-last
};