import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient,  HttpHeaders } from '@angular/common/http';

/** */
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ValidarToken } from '../validators/valida-datos';
import { Storage } from '@ionic/storage-angular';
/** */
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  urlWhats = environment.urlWhats;
  urlWhatsWs = environment.urlWhatsWs;
  classUser: any;
  constructor(private http: HttpClient,
              private storage: Storage
    ) {
   }

  reiniciarServ(url){
    const data ={};
    return this.http.post(url + '/reiniciar.php', data);
  }

  consultarQr(idAdmin){

  }

  getInfoUser(idAdmin, id, role, token) {
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.urlWhats}/getUsuario/${idAdmin}/${id}/${role}?ad_planIt=true`, {headers});
  }


  getQr(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.urlWhats}/obtenerUrlQr/${idAdmin}/${id}/${role}?ad_planIt=true`, {headers});
    //obtenerUrlQr
  }

  getStatusSesion(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.urlWhats}/consultaSesion/${idAdmin}/${id}/${role}`, {headers});
  }

  getHistorialConver(query, id, role, token, paginacion, body){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.urlWhats}/reporteChatsConverFechas/${id}/${role}?${query}&paginacion=${paginacion}`,
    body, {headers});
  }

  getHistorialChatsBot(query, idAdmin, id, role, token) {
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.urlWhats}/getNumsBot/${idAdmin}/${id}/${role}?${query}&ad_planIt=true`, {headers});
  }

  getReporteEfectividad(query, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.urlWhats}/reporteProductividadAgentes/${id}/${role}?${query}&ad_planIt=true`, {headers});
  }

  getChat(query, id, role, token){
    const headers = new HttpHeaders()
        .set('rol-token', token);
    return this.http.get(`
    ${this.urlWhats}/historialChatIndividual/${query.id_admin}/${query.id_agente}/${query.id_cliente}/${query.conversacion}/${id}/${role}`,
    {headers});
  }

  getChatInCompl(query, id, role, token){
    const headers = new HttpHeaders()
        .set('rol-token', token);
    return this.http.get(`
    ${this.urlWhats}/historialChatIndividualCompleto/${query.id_admin}/${query.id_cliente}/${query.conversacion}/${id}/${role}`,
    {headers});
  }

  getChatBot(query, fechas, id, role, token){
    const headers = new HttpHeaders()
        .set('rol-token', token);
    return this.http.get(`
    ${this.urlWhats}/getNumChatsBot/${query.idAdmin}/${query.numero}/${id}/${role}?ad_planIt=true`, {headers});
  }

  update(query, idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.put(`
    ${this.urlWhats}/updateAdmin/${idAdmin}/${id}/${role}?ad_planIt=true`, query, {headers});
  }

  getSucursales(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.urlWhats}/sucursales/${idAdmin}/${id}/${role}?ad_planIt=true`, {headers});
  }

  getSucursalesActivas(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.urlWhats}/sucursalesActivas/${idAdmin}/${id}/${role}`, {headers});
  }

  getAgentesSucursales(idAdmin, idSucursal) {
    return this.http.get(`${this.urlWhats}/agentesxSucursal/${idAdmin}/${idSucursal}`);
  }

  sendMessage(query, id){
    return this.http.post(`
    ${this.urlWhatsWs}enviarMensajeAgente/${id}`, query);
  }

  saveKeyNotPush(keys, usuario) {
    return this.http.post(`
    ${this.urlWhats}/saveKeyNot/${usuario}`, keys);
  }

  deletKeyNotPush(keys, usuario) {
    return this.http.delete(`
    ${this.urlWhats}/deleteKeyNot/${usuario}/${keys.keys.auth}/${keys.keys.p256dh}`);
  }

   // api para consultar las campañas

   consultarCampanas(idAdmin){
    return this.http.get(`${this.urlWhats}/consultarCampanas/${idAdmin}/`);
  }


  //consultar busisness id 

consultaBusiness(idAdmin){
    return this.http.get(`${this.urlWhats}/consultarIdBusiness/${idAdmin}/`);
  }


  // consultar calificacion


  consultarCalifiacion(idAdmin, id, role, token, data){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.urlWhats}/consultarCalif/${id}/${role}`,data ,{headers});
    // return this.http.post(`${this.urlWhats}/consultarCalif/${id}/${role}`,data );
  }

  reportePlantillas(idadmin, query, id, role, token, body){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.urlWhats}/reportePlantillas/${idadmin}/${id}/${role}?${query}`, body, {headers});
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // worksheet['!rows'] = 'A3:K3';
    // console.log('worksheet',worksheet);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const workbook: XLSX.WorkBook = { Sheets: { Reporte: worksheet }, SheetNames: ['Reporte'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

}
