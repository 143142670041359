export const environment = {
  production: true,


  //urlWhats:'http://localhost:3801/api',
  //urlWhatsWs:'http://localhost:3801/',


 // urlWhats:'https://e234-2806-2a0-1118-88ab-459-5b0-6c36-1c0d.ngrok-free.app/api',
 // urlWhatsWs:'https://e234-2806-2a0-1118-88ab-459-5b0-6c36-1c0d.ngrok-free.app/',

  //https://e234-2806-2a0-1118-88ab-459-5b0-6c36-1c0d.ngrok-free.app

  //------ Kaloni --------
   urlWhats:'https://kaloni.iatich.de/backend/api',
   urlWhatsWs:'https://kaloni.iatich.de/',

  //------ Consubanco --------
  // urlWhats:'https://consubanco.iatich.de/backend/api',
  // urlWhatsWs:'https://consubanco.iatich.de/',

  //------ Consubanco - infonavit --------
  // urlWhats:'https://clearvoiceinfonavit.iatich.de/backend/api',
  // urlWhatsWs:'https://clearvoiceinfonavit.iatich.de/',

     //urlWhats: 'https://barbering.iatich.de/loginwa/api',
    // urlWhatsWs: 'https://barbering.iatich.de/',

    //----------waldos--------
  //  urlWhats :"https://waldos.iatich.de/backend/api",
  // urlWhatsWs:"https://waldos.iatich.de/",


  //------boston------------
//  urlWhats :"https://channel.iatich.de/boston/api",
//  urlWhatsWs:"https://channel.iatich.de/",


  //-------------ike------------------
  // urlWhats :"https://ike.iatich.de/multicanal/api",
  // urlWhatsWs:"https://ike.iatich.de/",

  //-------------Goapps------------------
  // urlWhats :"https://checkingfleet.iatich.de/backend/api",
  // urlWhatsWs:"https://checkingfleet.iatich.de/",



// urlWhats:'https://3553-2806-2a0-1118-88ab-c0b0-f6d6-da0a-1c6b.ngrok-free.app/api',
// urlWhatsWs:'https://3553-2806-2a0-1118-88ab-c0b0-f6d6-da0a-1c6b.ngrok-free.app/',


    // urlWhats: 'https://barbering.iatich.de/botwa/api',
 url: 'https://barbering.iatich.de/cdmx/api',
 storageUrl:'https://barbering.iatich.de/storage/',
 urlPy: 'https://6htpaqttlldqwcpiciss2sa7wa0wgyhf.lambda-url.us-east-1.on.aws/',
 //mapKeyApi: "AIzaSyAhy7AzaREqAVZuW_cx-XYpVHSgYtkyKvo"
//  urlRostros: 'http://localhost:3500'
// urlWhats: 'https://channel.iatich.de/boston/api',
// urlWhatsWs: 'https://channel.iatich.de/',
urlRostros: 'https://barbering.iatich.de/gpsrasspertech'
};
