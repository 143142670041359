import { Component } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { SwPush } from '@angular/service-worker';
//import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { AlertController, LoadingController, MenuController, NavController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { SubjectService } from './services/subject.service';
import { WhatsappService } from './services/whatsapp.service';
import { ValidarToken } from './validators/valida-datos';
import { ChatService } from './services/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  usuario;
  nombre;
  rol;
  idAdmin;
  leads: boolean;
  banderMenuLogin;
  public appPages;
  tokenNotPush: any;
  classUser: any;
  masivos:  boolean
  //clientes: boolean;
  versionMovil:boolean
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly VAPID_PUBLIC_KEY = 'BPMND1TCD6u2cO1sjGh8hgxqdmnNcVi4EmsbFGsQ9L70KTnY5-En56XOQbuDh66PxL11AgzKTgnwcpF4CnbLbMg';
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private storage: Storage,
              private navCtrl: NavController,
              private menu: MenuController,
              private auth: AuthService,
              private swPush: SwPush,
              public subject: SubjectService,
              private whatsappserv: WhatsappService,
              private alertController: AlertController,
              private chatService: ChatService,
              public loadingController: LoadingController,

              ) {
             // this.subject.currentSubject$.subscribe()
             this.subject.currentSubject$.subscribe(val => {
              // console.log(val);
              this.banderMenuLogin = val;
              if(this.banderMenuLogin){
                this.ionViewWillEnter();
              }
            });

            this.versionMovil = false
  }

  async ngOnInit() {
    // this.classUser = new ValidarToken(this.storage);
    await this.storage.create()
    .then(()=>{
      this.getDatosUsuario();
    });
    //await this.getDatosUsuario()




  }

   ionViewWillEnter() {
    this.classUser = new ValidarToken(this.storage);
    setTimeout(() => {
      this.getDatosUsuario();
    }, 500);




  }

  ionViewDidEnter() {
    //this.getDatosUsuario()
  }

  solicitudNot(): any {
    // return new Promise((resolve, reject) => {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
    .then(tokenNotPush => {
      this.tokenNotPush = JSON.parse(JSON.stringify(tokenNotPush));
      // console.log(this.tokenNotPush);
      this.eliminarKeyNot();
      // return resolve(true);
      console.log('entra');
    })
    .catch(err => {
      this.tokenNotPush = err;
      console.log(err);
      // this.logOut();
      // return reject(false);
    });
  // });
  }

  eliminarKeyNot() {

    this.storage.get('usuario').then((usuario) => {

    // console.log(id);
    this.whatsappserv.deletKeyNotPush(this.tokenNotPush, usuario.correo).subscribe(
      (res: any) => {
        // this.logOut();
        console.log('entra');
        if(res.status === 'success') {
          // console.log(res.message);
          this.storage.remove('idAdmin');
        } else {
          this.presentAlert('Error!', 'No se elimino el token', '');
        }
      },
      error => {
        console.log(
          error
        );
      }
    );
  });
  }

   getDatosUsuario() {
     this.storage.get('usuario').then((usuario) => {
      this.usuario = usuario;
      // console.log(this.usuario, 'desde menu');
    })

    .then(()=>{
     this.nombre =  this.usuario.nombre;
     this.rol = this.usuario.rol;
     this.idAdmin = this.usuario.idAdmin;


        this.auth.getInfoUser(this.idAdmin).subscribe((res: any)=>{
                 console.log(res, 'desde menu');
                const { status} = res;

                if(status === 'success'){


                  if(res.leads == 1){
                    this.leads = true;
                  }

                  if(res.masivos==1){
                    this.masivos = true
                  }
}
    });

    })
    .then(()=>{
      this.verificarDispositivo()
    })
//     .then(()=>{

//             if(this.usuario.rol =='agente'){
// this.appPages  = [
//   // { title: 'Inicio', url: '/folder/inicio', icon: 'home' },
//    //{ title: 'Panel de control', url: '/folder/inicio', icon: 'cog' },
//    { title: 'Whatsapp', url: '/folder/whatsapp', icon: 'phone-portrait' },
//    //{ title: 'Leads', url: '/folder/leads', icon: 'list' },
//  ];
//       }


//       if(this.usuario.rol =='admin'){
// //console.log(this.idAdmin)

//         this.auth.getInfoUser(this.idAdmin).subscribe((res:any)=>{
//                 console.log(res)
//                 const { status} = res

//                 if(status=='success'){


//                   if(res.leads ==1){

//                     this.appPages  = [
//                       // { title: 'Inicio', url: '/folder/inicio', icon: 'home' },
//                        { title: 'Panel de control', url: '/folder/inicio', icon: 'cog' },
//                        { title: 'Whatsapp', url: '/folder/whatsapp', icon: 'phone-portrait' },
//                        { title: 'Leads', url: '/folder/leads', icon: 'list' },
//                      ];
//                   }else{

//                     this.appPages  = [
//                       // { title: 'Inicio', url: '/folder/inicio', icon: 'home' },
//                        { title: 'Panel de control', url: '/folder/inicio', icon: 'cog' },
//                        { title: 'Whatsapp', url: '/folder/whatsapp', icon: 'phone-portrait' },
//                        //{ title: 'Leads', url: '/folder/leads', icon: 'list' },
//                      ];
//                   }

//                 }
//         })



//         this.appPages  = [
//           // { title: 'Inicio', url: '/folder/inicio', icon: 'home' },
//            { title: 'Panel de control', url: '/folder/inicio', icon: 'cog' },
//            { title: 'Whatsapp', url: '/folder/whatsapp', icon: 'phone-portrait' },
//            { title: 'Leads', url: '/folder/leads', icon: 'list' },
//          ];
//               }


//     })
  }

  cerrarSesion(){

   // await this.solicitudNot();
    this.notificacion();
    this.cambioPendiente();
    setTimeout(() => {

      this.storage.remove('token');
      this.storage.remove('usuario')
      .then( () => {
        this.menu.close();
        this.navCtrl.navigateForward('/login');
      })
      .then(()=>{
        window.location.reload();
      });
    }, 500);
  }

  cambioPendiente() {

    this.storage.get('usuario').then((usuario) => {
      this.classUser = new ValidarToken(this.storage);
      setTimeout(() => {
      console.log(usuario.idAdmin, this.classUser.id, this.classUser.id,
        usuario.rol, this.classUser.token);
      if(usuario.rol == 'agente') {
        this.chatService.asignacionPendienteCierreSesion(usuario.idAdmin, this.classUser.id, this.classUser.id,
          usuario.rol, this.classUser.token).subscribe((res:any) => {
            console.log(res);
          })
      } else {
        console.log('Es admin o supervisor');
      }

    }, 500);

    });
  }

  notificacion(){
    this.solicitudNot();
  }

  async presentAlert(header, subheader, mensaje) {
    const alert = await this.alertController.create({
      cssClass: 'animate__animated animate__fadeIn',
      header,
      subHeader: subheader,
      message: mensaje,
      buttons: ['OK'],
    });

    await alert.present();
  }


  ir(ruta){
    this.presentLoading('cargando...')
    this.navCtrl.navigateForward('/folder/'+ruta)
    .then(()=>{
      if(ruta=='inicio')
      window.location.reload();
    })
  }

  async presentLoading(mensaje) {
    const loading = await this.loadingController.create({
      spinner:'dots',
      //message: mensaje,
      duration: 2000,
      cssClass:'loaderGeneral',
      message:'<ion-img src="../assets/ligero2.png" ></ion-img>'
    });
  await loading.present();
}

 dismissLoading() {
  // return await this.loading.dismiss().then(() => console.log('dismissed'));
  this.loadingController.dismiss();
}


verificarDispositivo(){



  if( navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i)){
    this.versionMovil = true
  }else this.versionMovil = false


 //console.log('esta es la verificacion',verificacion())


}
}
