import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment.prod';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DatePipe } from '@angular/common';

//import { NgxImageZoomModule } from 'ngx-image-zoom';
//import { FiltroAgentesPipe } from './pipes/filtro-agentes.pipe';
//import { PipesModule } from './pipes/filtroAgente/pipes.module';
//import { UrlSafePipe } from './pipes/url-safe.pipe';

const config: SocketIoConfig = { url: environment.urlWhatsWs, options: {
  // path: '/loginwa/',
  transports: ['websocket', 'polling']
} };

@NgModule({
  declarations: [AppComponent,
    //FiltroAgentesPipe
    // UrlSafePipe
    ],
  entryComponents: [],
  imports: [BrowserModule,
     IonicModule.forRoot(),
     SocketIoModule.forRoot(config),
      AppRoutingModule,

      //NgxImageZoomModule,
      //PipesModule,
      HttpClientModule,
      IonicStorageModule.forRoot(),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      }),
      //ServiceWorkerModule.register('pwa-reload-worker.js', { enabled: environment.production })

    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
