import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  constructor() { }

  currentSubject$ = new BehaviorSubject(false);

  //actualizar menu de usuario conforme al rol
  menuEvento(){

    this.currentSubject$.next(true);
    
  }

  desconexionEvento(bandera){
    this.currentSubject$.next(bandera);
  }
}
