import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { SubjectService } from './subject.service';


@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public socketStatus = false;
  constructor(private socket: Socket,  public subject: SubjectService,) {
    this.checkStatus();
  }

  checkStatus(){

    this.socket.on('connect', ()=>{
      console.log('conectado al servidor');
      this.socketStatus = true;
      this.subject.desconexionEvento(true)
    });



    this.socket.on('disconnect', ()=>{
      console.log('desconectado del servidor');
      this.socketStatus = false;
      this.subject.desconexionEvento(false)
    });
  }

  emit(evento: string, payload?: any, callback?: Function){

    this.socket.emit(evento, payload, callback);

  }

  listen(evento: string){

    return this.socket.fromEvent(evento);
  }

  loginWS(correo: string, idAdmin, idAgente){

    console.log('configurando', );
    this.socket.emit('configurarAgente',
    {
      correo,
      idAdmin,
      idAgente
    }, (res)=>{
      // console.log(res)
    });
  }

  cerrarSesion(correo: string, idAdmin, idAgente){
    console.log('cerrando sesion');

   console.log('configurando');
    this.socket.emit('cerrarSesion',
    {
      correo,
      idAdmin,
      idAgente
    }, (res)=>{
      // console.log(res)
    });
  }

}
