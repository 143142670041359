import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url = environment.url;
  urlWhats = environment.urlWhats;
  constructor(private http: HttpClient) { }

  // login(credenciales){
  //   return this.http.post(`${this.url}/login`, credenciales);
  // }

  login(credentials){


    return this.http.post(this.url + '/ingresar', credentials);
  }

  // api para login de agentes en plataforma multiagentes
  loginAgente(data){

    return this.http.post( this.urlWhats + '/loginAgente', data);
  }

  // api para login de admins en plataforma multiagentes
  loginAdmin(data){

    return this.http.post(this. urlWhats + '/loginAdmin', data);
  }

  getInfoUser(id) {
    return this.http.get(`${this.urlWhats}/appC/getUsuario/${id}`);
  }

  mailPassAdmin(body){

    //return this.http.post(this.url + '/admin/cambioPassMail', body);
    return this.http.post(`${this.urlWhats}/mailPass/`,body);

  }

  mailPassAgente(body){

    return this.http.post(`${this.urlWhats}/cambiarPasswordAgente/`,body);

  }




  mailPassChangeAdmin(id, data){

   // return this.http.put(this.url + '/admin/cambioPassword', body);

   return this.http.put(`${this.urlWhats}/updatePass/${id}`, data);
  }


  mailPassChangeAgente(idAdmin, idAgente, data){

    // return this.http.put(this.url + '/admin/cambioPassword', body);

    return this.http.put(`${this.urlWhats}/pass/updateAgente/${idAdmin}/${idAgente}`, data);
   }

  comprobarRol(correo){
    return this.http.get(`${this.urlWhats}/comprobarCuenta/${correo}`);
  }
}
