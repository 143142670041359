// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //url:'http://localhost:9000/api',
  //url:'https://backend-buggy.herokuapp.com/api',
   urlWhats:'https://multiagente-api.herokuapp.com/api',
   urlWhatsWs:'https://multiagente-api.herokuapp.com/',
  //urlWhats:'http://localhost:3801/api',
 //urlWhatsWs:'http://localhost:3801/',

 // urlCanal:'http://localhost:3800',
  url: 'https://barbering.iatich.de/cdmx/api',
  storageUrl:"https://barbering.iatich.de/storage/upload/webappstich/buggy",
  mapKeyApi: "AIzaSyAhy7AzaREqAVZuW_cx-XYpVHSgYtkyKvo"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
