import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private storage: Storage,
    ){}


    async canActivate(){

      const logged =  await this.storage.get('usuario');
  
  
      if(logged != null){
      return true;
      }
      if(!logged){
        this.router.navigateByUrl('/login');
      }
      else{
      this.router.navigateByUrl('/login');
      }
    }
  
}
