import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';


@Injectable({
  providedIn: 'root'
})
export class ChatService {
  url = environment.urlWhats;
  // urlMasivos = environment.urlMasivos;
  urlWs = environment.urlWhatsWs;
  urlStorage = environment.storageUrl;

  urlConsultaTexto = 'https://barbering.iatich.de/openai/api/consultaTexto';

  constructor(
    public wsService: SocketService,
    private http: HttpClient
  ) { }



  sendMessage(mensaje: string, de){

    const payload ={
      de,
      cuerpo: mensaje
    };

    this.wsService.emit('mensaje', payload);

  }

  //consultar cliente asignado a agente
  // consultarNumeroCliente(idAdmin, data){
  //   return this.http.post(`${this.url}consultarIdSocketAgente/${idAdmin}`, data);
  // }

  consultarNumerosAgente(idAdmin, idAgente, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getNumeros/${idAgente}/${idAdmin}/${id}/${role}`, {headers});
  }

  consultarSedes(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/sedes/${idAdmin}/${id}/${role}`, {headers});
  }


  enviarWhatsapp(url, data, id, role, token) {
    data.url = url;
    const headers = new HttpHeaders()
    .set('rol-token', token);
    //return this.http.post(`${url}send.php/`, data);
    return this.http.post(`${this.url}/send/${id}/${role}`, data, {headers});
  }

  enviarWhatsappBd(data, id, role, token) {

    const headers = new HttpHeaders()
    .set('rol-token', token);
    //return this.http.post(`${url}send.php/`, data);
    return this.http.post(`${this.url}/sendCompleto/${id}/${role}`, data, {headers});
  }

  // messenger facebook

  enviarMensajeFacebookMessenger(data, id, role, token, idFacebookMessenger) {

    const headers = new HttpHeaders()
    .set('rol-token', token);
    //return this.http.post(`${url}send.php/`, data);
    return this.http.post(`${this.url}/sendCompleto/${id}/${role}?idFacebookMessenger=${idFacebookMessenger}&idMuro=0&tipoCanal=1`, data, {headers});
  }

  enviarMensajeMuro(data, id, role, token, idFacebookMessenger, idMuro){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    //return this.http.post(`${url}send.php/`, data);
    return this.http.post(`${this.url}/sendCompleto/${id}/${role}?idFacebookMessenger=${idFacebookMessenger}&idMuro=${idMuro}&tipoCanal=2`, data, {headers});
  }




  guardarNuevoChat(mensjeObj, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/planIt/newMessage/${id}/${role}`, mensjeObj, {headers});
  }

  consultarIdNumero(idAdmin, idAgente, numero){
    return this.http.get(`${this.url}/planIt/getId/${idAdmin}/${idAgente}/${numero}`);
  }


  getMessages(){
    return this.wsService.listen('mensaje-nuevo');
  }

  getMessagesPrivate(){

    return this.wsService.listen('mensaje-privado');
  }

  getAlertaCampana(){
    return this.wsService.listen('alerta-campana');
  }

  getStatusUpdateChat(){
    return this.wsService.listen('update-status');
  }

  getUpdateDashboard(){
    return this.wsService.listen('update-dashboard');
  }

  getUpdateSemaforo(){
    return this.wsService.listen('alerta-semaforo');
  }

  getTipificacion(){
    return this.wsService.listen('alerta-resultado');
  }


  enviarMensajePrivadoAgente(idSocket, mensaje){
    return this.http.post(`${this.urlWs}enviarMensajeAgente/${idSocket}`, mensaje);
  }

  enviarMensajePrivadoSemaforo(idSocket, mensaje){
    return this.http.post(`${this.urlWs}alertaSemaforo/${idSocket}`, mensaje);
  }


  //conversaciones de agente
  getConversacionesDeNumero(idAdmin, idAgente, idNumero, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getChat/${idAdmin}/${idAgente}/${idNumero}/${id}/${role}`, {headers});

  }

  //conversaciones de agente versión optimizada

  getConversacionesNumeroOptimizado(idAdmin, idAgente, idNumero, id, role, token, conv){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getChatConver/${idAdmin}/${idAgente}/${idNumero}/${conv}/${id}/${role}`, {headers});

  }



  //conversaciones de admin
  getNumerosAdmin(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getNumerosAdmin/${idAdmin}/${id}/${role}`, {headers});
  }

  ///api para acutalizar que el numero asignado al agente tiene un nuevo mensaje
  mensajeNuevoCliente(data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.put(`${this.url}/actualizarNumeroNuevoAgente/${id}/${role}`, data, {headers});
  }

  mensajeNuevoClienteMessengerFacebok(data, id, role, token, idFacebookMessenger){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.put(`${this.url}/actualizarNumeroNuevoAgente/${id}/${role}?idFacebookMessenger=${idFacebookMessenger}&idMuro=0&tipoCanal=1`, data, {headers});
  }

  mensajeNuevoClienteMuro(data, id, role, token, idFacebookMessenger, idMuro){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.put(`${this.url}/actualizarNumeroNuevoAgente/${id}/${role}?idFacebookMessenger=${idFacebookMessenger}&idMuro=${idMuro}&tipoCanal=2`, data, {headers});
  }


  finalizarChat(data, numero, idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.put(`${this.url}/planIt/updateNum/${numero}/${idAdmin}/${id}/${role}`, data, {headers});
  }

  bloquearNum(data, numero, idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.put(`${this.url}/bloquearNum/${numero}/${idAdmin}/${id}/${role}`, data, {headers});
  }

  //actualizar estatus del numero para que reciba calificación del agente

  updateStatusNumero(idAdmin, data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.put(`${this.url}/switch-bot/${idAdmin}/${id}/${role}`, data, {headers});
  }

  //crea el registro para la calificacion de agente

  agregarCalifAgente(data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/agregarCalifAgente/${id}/${role}`, data, {headers});
  }

  uploadImgPlan(nombre, id, url, data){
    return this.http.post(`${url}uploadImagen/${nombre}/${id}`, data);
  }

  getImgPlan(nombre, url){
    return this.http.get(`${url}getImagen/${nombre}/`);
  }

  consultarRespuestasRapidas(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/obtenerRespuestaRapida/${idAdmin}/${id}/${role}`, {headers});

  }

  //------- Asignación pendiente en cierre de sesion -----
  asignacionPendienteCierreSesion(idAdmin, idAgente, id, role, token){
    console.log(idAdmin, idAgente, id, role, token);
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/asignacionPendienteCierreSesion/${idAdmin}/${idAgente}/${id}/${role}`, {headers});

  }

  getListaEstatus(idAdmin, id, role, token, query){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/obtenerListaEstatus/${idAdmin}/${id}/${role}?${query}`, {headers});
  }

  getUrlAdmin(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/obtenerUrl/${idAdmin}/${id}/${role}`, {headers});
  }

  //storage aws
  uploadArchivo(data, id){
    // console.log(data, id, 'service')
    return this.http.post(`${this.urlStorage}upload/planitwhatsapp/${id}`, data);
  }

  //delete aws
  deletAwsArchivo(id){
    return this.http.delete(`${this.urlStorage}delete-object/planitwhatsapp/${id}`);
  }

//servidor que aloja el canal de whatsapp
  uploadArchivoServer(url, data, numero, tipo, id, role, token, caption){
    return this.http.post(`${url}upload/${numero}/${tipo}/${id}/${role}/${token}/${caption}`, data);
  }


  //servidor que aloja el canal de FacebookMessenger
  uploadArchivoServerFacebookMessenger(url, data, numero, tipo, id, role, token){
    return this.http.post(`${url}upload/${numero}/${tipo}/${id}/${role}/${token}`, data);
  }


  //registro de datos del cliente

  guardarRegistroCliente(data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/agregarRegCliente/${id}/${role}`, data, {headers});
  }

  //consulta registro del cliente
  getRegistroCliente(idNumero, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getRegCliente/${idNumero}/${id}/${role}`, {headers});
  }


  // consulta registro de cliente version 2
  getResultadoChat(idAdmin,conv,idNumero, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getResultadoChat/${idAdmin}/${conv}/${idNumero}/${id}/${role}`, {headers});
  }

  // consulta Estados de agente
  getEstadosAgente(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getEstadoAgente/${idAdmin}/${id}/${role}`, {headers});
  }


  //actualizacion de datos cliente

  actualizarRegistroCliente(data,idNumero, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.put(`${this.url}/updateRegCliente/${idNumero}/${id}/${role}`, data, {headers});
  }


  //guardar notas y status en chats

  guardarNotaStatus(data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.put(`${this.url}/updateNotaStatus/${id}/${role}`, data, {headers});
  }


  enviarPlantilla(url, data, id, role, token){
    data.url = url;
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/sendPlantilla/${id}/${role}`, data, {headers});
  }

  registrarEnvioPlantilla(data,id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    //return this.http.post(`${this.url}/registrarEnvioPlantilla/${id}/${role}`, data, {headers});
    return this.http.post(`${this.url}/registrarEnvioPlantilla`, data);
  }

  consultarPlantillas(idAdmin, id, rol,token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/obtener-plantillas/${idAdmin}/${id}/${rol}?ad_planit=true&status=1`, {headers});
  }

  consultarPlantilla(idAdmin, nombre_whatsapp, id, rol,token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getPlantilla/${idAdmin}/${nombre_whatsapp}/${id}/${rol}?ad_planit=true`, {headers});
  }

  consultarAsignacionPendiente(idAdmin, idAgente, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/asigPendNumero/${idAdmin}/${idAgente}/${id}/${role}?sucursal=1`, {headers});
  }

  consultarOrigenes(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getOrigenes/${id}/${role}?admin=${idAdmin}`, {headers});
  }

  consultarNombresForms(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/consultarNombresForms/${id}/${role}?admin=${idAdmin}`, {headers});
  }

  consultarIdsForms(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/consultarIdForms/${id}/${role}?admin=${idAdmin}`, {headers});
  }

  consultarGraficaConver(idAdmin, id, role, token, query){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/graficaBarrasConver/${idAdmin}/${id}/${role}${query}`, {headers});
  }

  getMetricasNum(idAdmin, id, role, token, query){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/metricasNum/${idAdmin}/${id}/${role}${query}`, {headers});
  }

  getPlantillasDashboard(idAdmin, id, role, token, query){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/dashboardPlantillas/${idAdmin}/${id}/${role}${query}`, {headers});
  }

  getTasasConver(idAdmin, id, role, token, query){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/tasasConver/${idAdmin}/${id}/${role}${query}`, {headers});
  }

  consultarGraficaResultado(idAdmin, id, role, token, query, data){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/graficaResultados/${idAdmin}/${id}/${role}${query}`, data, {headers});
  }

  consultarGraficaOrigen(idAdmin, id, role, token, query){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/graficaOrigen/${idAdmin}/${id}/${role}${query}`, {headers});
  }

  asigacionAgente(idAdmin, data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    //consultarAsignacionAgente/:idAdmin
    return this.http.post(`${this.url}/planIt/consultarAsignacionAgente/${idAdmin}/${id}/${role}`, data, {headers});
  }

  consultarSockets(idAdmin, data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/planIt/consultarIdSocketAgente/${idAdmin}/${id}/${role}`, data, {headers});
  }

  addPlantilla(data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/guardar-plantilla/${id}/${role}?ad_planIt=true`, data, {headers});
  }

  consultaPlantillas(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/obtener-plantillas/${idAdmin}/${id}/${role}?ad_planIt=true`, {headers});
  }

  consultaPlantillasStatus(idAdmin, id, role, token, status){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/obtener-plantillas/${idAdmin}/${id}/${role}?ad_planIt=true&status=${status}`, {headers});
  }

  consultaSaldoSms(idAdmin, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/consultaSaldoRegSms/${idAdmin}/${id}/${role}?ad_planIt=true`, {headers});
  }

  upload(data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/uploadCsv/${id}/${role}?ad_planIt=true&nomCamp=1`, data, {headers});
  }

  generarMasivo(data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/generarMasivo/${id}/${role}?ad_planIt=true`, data, {headers});
  }

  saldoDisponible(idadmin, data, id, role, token){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/disponibilidadMasivos/${idadmin}/${id}/${role}`, data, {headers});
  }


  // RE ASIGNACION DE AGENTE
  updatedatosNumero(idadmin,from, data, id, role, token, evento, query){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.put(`${this.url}/planIt/updateNum/${from}/${idadmin}/${id}/${role}?evento=${evento}${query}`, data, {headers});

  }


  // RE ASIGNACION DE SUCURSAL
  reAsignarSucursal(idadmin,from,sucursal, id, role, token, query){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(
      `${this.url}/planIt/asignacionAgente/${idadmin}/${from}/${id}/${role}?&sucursal=${sucursal}&asigSuc=1&agenteAntAsig=${id}${query}`, {
        headers});

  }


  //notificar web push

  notificacionPush(agenteAsignado, idAdmin, id, role, token, data){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/planIt/sendNotPush/${agenteAsignado}/${idAdmin}/${id}/${role}`, data, {headers});

  }


  //consultar reportes disponibles para descarga

  consultaReportesDisponibles(idAdmin){
    // const headers = new HttpHeaders()
    // .set('rol-token', token);
    return this.http.get(`${this.url}/consultarReportesDisponibles/${idAdmin}`);
  }

  finalizarChatGeneral(idAdmin, idAgente, idWhats, numeroWhats, id, role, token , data){

    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/finalizarChat/${idAdmin}/${idAgente}/${idWhats}/${numeroWhats}/${id}/${role}`, data, {headers});
    //finalizarChat/:idAdmin/:idAgente/:idCliente/:numero/:id/:role
  }

  finalizarChatGeneralMessengerFaebook(idAdmin, idAgente, idWhats, numeroWhats, id, role, token , data){

    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/finalizarChat/${idAdmin}/${idAgente}/${idWhats}/0/${id}/${role}?idFacebookMessenger=${numeroWhats}&idMuro=0&tipoCanal=1`, data, {headers});
    //finalizarChat/:idAdmin/:idAgente/:idCliente/:numero/:id/:role
  }

  finalizarChatGeneralMuro(idAdmin, idAgente, idWhats, numeroWhats, id, role, token , data){

    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/finalizarChat/${idAdmin}/${idAgente}/${idWhats}/0/${id}/${role}?idFacebookMessenger=${numeroWhats}&idMuro=0&tipoCanal=2`, data, {headers});
    //finalizarChat/:idAdmin/:idAgente/:idCliente/:numero/:id/:role
  }


  consultarHistorialChats(idAgente, rol, idAdmin, token){
    const data ={};
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/consultaNumHistoricos/${idAgente}/${rol}?admin=${idAdmin}&agente=${idAgente}`, data, {headers});

  }


  consultarStatusNumeros(idAdmin, id, rol, token, data, query){
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.post(`${this.url}/planIt/consultaStatus/${idAdmin}/${id}/${rol}?${query}`,data ,{headers});

  }

  consulta24hrs(idAdmin, idCliente, conversacion, id, rol, token){
    //consulaChat24hrs/:idCliente/:idAdmin/:conversacion/:id/:role
    const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/consulaChat24hrs/${idCliente}/${idAdmin}/${conversacion}/${id}/${rol}`, {headers});
  }

consultarNumeroIndividual(numero, idAdmin, id, rol, token, query){
  //getNumeroBypk/:numero/:idAdmin/:id/:role}
  const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getNumeroBypk/${numero}/${idAdmin}/${id}/${rol}?${query}`, {headers});
}

envioRecetario(archivo){
  //getNumeroBypk/:numero/:idAdmin/:id/:role}
  const headers = new HttpHeaders();
    // .set('rol-token', token);
    return this.http.post(`${this.url}/envioRecetario/`, archivo, {headers});
}

validacionEnvioPlantilla(numero, idAdmin, id, rol, token){
  //getNumeroBypk/:numero/:idAdmin/:id/:role}
  const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/validacionSendPlantilla/${idAdmin}/${numero}/${id}/${rol}`, {headers});
}


consultarCodigosPais(idAdmin, id, rol, token){
  const headers = new HttpHeaders()
  .set('rol-token', token);
  return this.http.get(`${this.url}/obtenerCodigosPais/`, {headers});
}

obtenerResultadoChat(idAdmin, conversacion,idCliente, id, rol, token){
  //getNumeroBypk/:numero/:idAdmin/:id/:role}
  const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getResultadoChat/${idAdmin}/${conversacion}/${idCliente}/${id}/${rol}`, {headers});
    //getResultadoChat/:idAdmin/:conversacion/:idCliente/:id/:role
}

// ---- Update plantilla -------
updatePlantilla(data, idPlantilla, id, role, token){
  const headers = new HttpHeaders()
  .set('rol-token', token);
  return this.http.put(`${this.url}/actualizar-plantilla/${idPlantilla}/${id}/${role}`, data, {headers});
}


getSemaforoAgentes(idAgente, idAdmin, id, rol, token,global){

  const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getNumAgenteSemaforo/${idAgente}/${idAdmin}/${id}/${rol}?global=${global}`, {headers});
   //getNumAgenteSemaforo/:idAgente/:idAdmin/:id/:role?global=1
}


getNumerosSinAsignar(idAdmin, id, rol, token){

  const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getNumerosNoAsignados/${idAdmin}/${id}/${rol}`, {headers});
   //getNumerosNoAsignados/:idAdmin/:id/:role
}


asignacionAutomatica(idAdmin, id, rol, token){

  const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/distribucionPendiente/${idAdmin}/${id}/${rol}`, {headers});
   //distribucionPendiente/:idAdmin/:id/:role
}

getultimoChat(idAdmin,idWhatsapp, id, rol, token){
  const headers = new HttpHeaders()
  .set('rol-token', token);
  return this.http.get(`${this.url}/getChatXconver/${idAdmin}/${idWhatsapp}/${id}/${rol}`, {headers});
  //getChatXconver/:idAdmin/:idCliente/:id/:role
}


//consultaChatXConver/:idNumero/:conver/:id/:role
//consultaChatXConver
getconversacionHistoricoChatResultado(idAdmin,idWhatsapp, conv, id, rol, token){
  const headers = new HttpHeaders()
  .set('rol-token', token);
  return this.http.get(`${this.url}/consultaChatXConver/${idWhatsapp}/${conv}/${id}/${rol}`, {headers});

  //consultaChatXConver/:idNumero/:conver/:id/:role
}

getHisorialChatsResultado(idWhatsapp, id, rol, token){
  //listaChatXResulatdos/:idNumero/:id/:role
  const headers = new HttpHeaders()
  .set('rol-token', token);
  return this.http.get(`${this.url}/listaChatXResulatdos/${idWhatsapp}/${id}/${rol}`, {headers});

}

//historial de chats cerrados para admins:

getHistorialChatsCerradosAdmin(idAdmin,cantidad, numero, id, rol, token){
  // getNumHistoricosPaginado/:id/:role?admin=8&paginacion=40&numero=52156
  const headers = new HttpHeaders()
  .set('rol-token', token);
  return this.http.get(`${this.url}/getNumHistoricosPaginado/${id}/${rol}?admin=${idAdmin}&paginacion=${cantidad}&numero=${numero}`, {headers});

}

getHistorialChatsCerradosAgente(idAdmin,cantidad, numero, agente, id, rol, token){
  // getNumHistoricosPaginado/:id/:role?admin=8&paginacion=40&numero=52156
  const headers = new HttpHeaders()
  .set('rol-token', token);
  return this.http.get(`${this.url}/getNumHistoricosPaginado/${id}/${rol}?admin=${idAdmin}&agente=${agente}&paginacion=${cantidad}&numero=${numero}`, {headers});

}


// nueva versión de semaforos

//atendidos
getSemaforoAgentesAten(idAgente, idAdmin, id, rol, token,global){
  //getNumagenteSemaforoAten/:idAgente/:idAdmin/:id/:role
  const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getNumagenteSemaforoAten/${idAgente}/${idAdmin}/${id}/${rol}?global=${global}`, {headers});

}

// sin respuesta
//getNumagenteSemaforoNoRes/:idAgente/:idAdmin/:id/:role

getSemaforoAgentesSinRes(idAgente, idAdmin, id, rol, token,global){

  const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getNumagenteSemaforoNoRes/${idAgente}/${idAdmin}/${id}/${rol}?global=${global}`, {headers});

}

//no atendidos
getSemaforoAgentesNoAtend(idAgente, idAdmin, id, rol, token,global){
  //getNumagenteSemaforoNoAten/:idAgente/:idAdmin/:id/:role
  const headers = new HttpHeaders()
    .set('rol-token', token);
    return this.http.get(`${this.url}/getNumagenteSemaforoNoAten/${idAgente}/${idAdmin}/${id}/${rol}?global=${global}`, {headers});

}

consultarConteoOpenIa(idAdmin, id, rol, token){
  const headers = new HttpHeaders()
  .set('rol-token', token);
  return this.http.get(`${this.url}/consultarConteoOpenIa/${idAdmin}/`, {headers});
}

actualizarConteoOpenIa(idAdmin, id, rol, token){
  const headers = new HttpHeaders()
  .set('rol-token', token);
  return this.http.put(`${this.url}/actualizarConteoOpenIa/${idAdmin}/`, {}, {headers});

}

/*
consultarTexto(): Observable<any> {
  const url = this.urlConsultaTexto;

  const requestOptions = {
      method: 'POST',
  };

  return new Observable<any>(observer => {
      fetch(url, requestOptions)
          .then(response => {
              if (!response.ok) {
                  throw new Error('Error en la solicitud');
              }
              return response.json();
          })
          .then(data => {

              console.log(data);
              observer.next(data);
              observer.complete();
          })
          .catch(error => {
              console.error('Error al consultar la API:', error);
              observer.error(error);
          });
  });
}*/

consultarTexto(data) {
  const headers = new HttpHeaders();
  //return this.http.post(`$this.url}`, data);
  return this.http.post(`${this.urlConsultaTexto}`, data, {headers});
}

contactos(idAdmin, id, role, token, query) {
  const headers = new HttpHeaders()
  .set('rol-token', token);
  return this.http.get(`${this.url}/getClientes/${idAdmin}/${id}/${role}?paginacion=0${query}`, {headers});
}


}
